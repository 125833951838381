<template>
    <div>
        <div class="modal fade" id="materialModal" tabindex="-1" aria-labelledby="materialModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="materialModalLabel" class="font-weight-bold">Add Material</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <kendo-grid ref="grid"
                            :data-source="DataSource"
                            :filterable="FilterableConfig"
                            :sortable="true"
                            :pageable="true"
                            :columns="Columns"
                            :selectable="true"
                            :editable="true"
                            :resizable="true"
                            >
                        </kendo-grid>
                        <br/>

                        <CButton type="button" @click="saveClick()" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';
import productionMasterServices from '../Script/ProductionMasterServices.js';

export default {
    name: 'MaterialForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['saveMaterialGrid'],
    mounted:  function () {

    },
    data: function () {
        return {
            DataSource: [],
            Columns: [
                { field: "storage_id", title: "Kode Gudang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                // { field: "storage_name", title: "Nama Gudang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "type_detail_id", title: "Kode Tipe Detail", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                // { field: "type_detail_name", title: "Nama Tipe Detail", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "product_code", title: "Kode Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "item_name", title: "Nama Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "base_uom", title: "Satuan", width: 50, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "stock", title: "Stock", width: 50, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} }, 
                { field: "quantity", title: "Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} }, 
            ],
            FilterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            MaterialSchemaModel: {
                storage_id: { editable: false },
                storage_name: { editable: false },
                product_code: { editable: false },
                item_name: { editable: false },
                type_detail_id: { editable: false },
                type_detail_name: { editable: false },
                base_uom: { editable: false },
                stock: { type: "number", editable: false },
                quantity: { type: "number", editable: true, validation: { min:0} }
            }
        }
    },
    methods: {
        async addClick(formulaId){
            if(this.DataSource.length == 0){
                var materialSchemaModel = this.MaterialSchemaModel;

                this.DataSource = new kendo.data.DataSource ({
                    page: 1,
                    pageSize: kendo_grid.default_grid_pagesize,
                    serverPaging: false,
                    serverFiltering: false,
                    serverSorting: false,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                const variables = {
                                    formulaId : formulaId
                                }
                                return { 
                                    query: productionMasterServices.readMaterialQuery(),
                                    variables : variables
                                };
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if(response.data.GetFormulaItemList == null){
                                return [];
                            }else{
                                return response.data.GetFormulaItemList.ItemStock;
                            }
                        },
                        total: function (response) {
                            if(response.data.GetFormulaItemList == null){
                                return 0;
                            }else{
                                return response.data.GetFormulaItemList.Total;
                            }
                        },
                        model: {
                            fields: materialSchemaModel
                        }
                    }
                });
            }

            window.$('#materialModal').modal('show');
        },
        saveClick(){
            var grid = this.$refs.grid.kendoWidget();
            var selectedItem = grid.dataItem(grid.select());

            if(selectedItem != null){
                // validation qty
                if(selectedItem.quantity == null || selectedItem.quantity == 0){
                    this.$swal("Error", "Quantity pada item yang dipilih tidak boleh kosong", "error");
                }else{
                    if(selectedItem.quantity > selectedItem.stock){
                        this.$swal("Error", "Quantity pada item yang dipilih tidak boleh melebihi stock", "error");
                    }else{
                        var data = productionMasterServices.itemMaterialDataObject(selectedItem)
                        this.$props.saveMaterialGrid(data);
                    }
                }
            }
            else{
                this.$swal("Error", "Dimohon untuk memilih item terlebih dahulu", "error");
            }
        }
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>