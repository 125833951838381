<template>
    <div>
        <div class="modal fade" id="qualityControlModal" tabindex="-1" aria-labelledby="qualityControlModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="qualityControlModalLabel" class="font-weight-bold">Quality Control</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CRow>
                            <CCol sm="4">
                                <label class="form-label font-weight-bold">Kode Item</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="8">
                                <CInput id="ItemCode" v-model="ItemCode" class="font-weight-bold" readonly/>
                                <label id="errorItemCode" class="form-error" style="display: none; color: red;"></label>
                            </CCol>                                    
                        </CRow>
                        <CRow>
                            <CCol sm="4">
                                <label class="form-label font-weight-bold">Nama Item</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="8">
                                <CInput id="ItemName" v-model="ItemName" class="font-weight-bold" readonly/>
                                <label id="errorItemName" class="form-error" style="display: none; color: red;"></label>
                            </CCol>                                    
                        </CRow>
                        <CRow>
                            <CCol sm="4">
                                <label class="form-label font-weight-bold required">Quantity QC</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="8">
                                <input type="number" id="Quantity" v-model="Quantity" class="font-weight-bold form-control input-number" min="0"/>
                                <label id="errorQuantity" class="form-error" style="display: none; color: red;"></label>
                            </CCol>                                    
                        </CRow>
                        <br>
                        
                        <CButton type="button" @click="saveClick()" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { response } from '../../../../infrastructure/constant/response';
import productionMasterServices from '../Script/ProductionMasterServices';

export default {
    name: 'QualityControlForm',
    components: {
    },
    props: ['saveQCGrid'],
    mounted:  function () {
    },
    data: function () {
        return {
            Error: 0,

            ProductionMasterId: null,
            ItemCode: null,
            ItemName: null,
            ProductionStatus: null,
            Quantity: 0,
        }
    },
    methods: {
        async addClick(productionMasterId, itemCode, itemName, status){ 
            $(".form-error").css("display", "none");  

            //Set Data
            this.ProductionMasterId = productionMasterId;
            this.ItemCode = itemCode;
            this.ItemName = itemName;
            this.ProductionStatus = status;
            this.Quantity = 0;

            window.$('#qualityControlModal').modal('show');
        },
        inputValidation(){
            this.Error = 0;
            
            if(this.Quantity.toString() == '' || this.Quantity == null){
                this.errorShow('errorQuantity');
            }
            else if (this.Quantity < 0){
                this.errorShow('errorQuantity', response.fieldRequiredNotMinus);
            }
            else if(this.Quantity == 0){
                this.errorShow('errorQuantity');
            }
            
            if(this.ProductionStatus.toLowerCase() != 'on progress') {
                this.$swal("Error", "Tidak dapat melakukan Request Quality Control karena status produksi belum 'On Progress'. Silahkan update status produksi terlebih dahulu", "error");
                this.Error++;
            }
        },
        async saveClick(){
            this.inputValidation();
            
            //Proses
            if(this.Error == 0){
                this.$loading(true);
                
                //QC Standar
                const variables = {
                    id : parseInt(this.ProductionMasterId),
                    quantity : parseFloat(this.Quantity),
                    mutationId : null,
                    data : null,
                }

                productionMasterServices.addQCQuery(variables).then(res => {
                    this.$loading(false);
                    this.$swal("Info", "Data telah berhasil disimpan, menunggu untuk disetujui", "success").then(
                        this.$props.saveQCGrid()
                    );
                }).catch(error => {
                    this.$loading(false);
                    var errorMessage = this.$globalfunc.errorExtractor(error)
                    this.$swal("Error", errorMessage, "error");
                })
            }
        },
        errorShow(errorCode, message){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = message == undefined ? response.fieldRequired : message;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>