<template>
    <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            :resizable="true"
        >
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import productionMasterServices from '../Script/ProductionMasterServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ProductionMasterGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    // props: ['deleteClick','editClick','statusClick'],
    props: ['deleteClick','editClick'],
    data: function () {
        return  {
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapfield = [
                                { field: "status", new_field: "\"master_production\".\"status\"" },
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapfield),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapfield)
                            }
                            return { 
                                query: productionMasterServices.readProductionMasterQuery(),
                                variables : {
                                    paging : paging
                                }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetMasterProductions == null){
                            return [];
                        }else{
                            return response.data.GetMasterProductions.productions;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetMasterProductions == null){
                            return 0;
                        }else{
                            return response.data.GetMasterProductions.total;
                        }
                    },
                    model: {
                        fields: {
                            production_start_dt: {type: "proddate"},
                            production_estimation_dt: {type: "proddate"},
                            production_end_dt: {type: "proddate"},
                            created_at: {type: "proddate"},
                            quantity: {type: "number"},
                            production_qty: {type: "number"},
                            approved_qty: {type: "number"},
                        }
                    }
                },
                sort: { field: "production_code", dir: "desc" }
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "production_code", title: "Kode Produksi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "production_schedule_code", title: "Kode Penjadwalan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "production_planning_code", title: "Kode Perencanaan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "production_schedule_order", title: "Urutan Produksi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama Item", width: 300, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "machine_name", title: "Nama Mesin", width: 300, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "production_qty", title: "Estimasi Produksi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "approved_qty", title: "Hasil Produksi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "production_start_dt", title: "Tanggal Mulai Produksi", width: 250, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, filterable: {ui: 'datepicker'} },
                { field: "production_estimation_dt", title: "Tanggal Estimasi Produksi", width: 250, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, filterable: {ui: 'datepicker'} },
                { field: "production_end_dt", title: "Tanggal Selesai Produksi", width: 250, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, filterable: {ui: 'datepicker'} },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, filterable: {ui: 'datepicker'} }
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
        };
    },
    mounted: async function() {
        var editClick = this.$props.editClick;
        var deleteClick = this.$props.deleteClick;
        // var statusClick = this.$props.statusClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.production_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.production_id, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            deleteClick(dataItem.production_id);   
        })

        // //Status Form
        // GridElement.on("click", "#StatusButton", function (e){
        //     e.preventDefault();
        //     var dataItem = grid.dataItem(e.target.closest("tr"));
            
        //     statusClick(dataItem);
        // })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Production Master")
        },
        detailInit: async function (e) {
            var data = await productionMasterServices.getProductionMasterQuery(e.data.production_id);

            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "productionMasterDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(data.detail_materials);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'material_name', title: "Nama Material", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'requested_quantity', title: "Request Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, editable: false, nullable: true },
                    { field: 'additional_quantity', title: "Additional Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, editable: false, nullable: true },
                    { field: 'used_quantity', title: "Used Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, editable: false, nullable: true },
                ],
            })
        },
        changeStatus(status) {
            this.dataSource = new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapfield = [
                                { field: "status", new_field: "\"master_production\".\"status\"" },
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapfield),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapfield)
                            }
                            return { 
                                query: productionMasterServices.readProductionMasterQuery(),
                                variables : {
                                    paging : paging,
                                    status : [status],
                                }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetMasterProductions == null){
                            return [];
                        }else{
                            return response.data.GetMasterProductions.productions;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetMasterProductions == null){
                            return 0;
                        }else{
                            return response.data.GetMasterProductions.total;
                        }
                    },
                    model: {
                        fields: {
                            production_start_dt: {type: "proddate"},
                            production_estimation_dt: {type: "proddate"},
                            production_end_dt: {type: "proddate"},
                            created_at: {type: "proddate"},
                            quantity: {type: "number"},
                            production_qty: {type: "number"},
                            approved_qty: {type: "number"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            })
        },
    },
}
</script>