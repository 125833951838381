<template>
    <div>
        <div class="modal fade" id="maintenanceModal" tabindex="-1" aria-labelledby="maintenanceModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="maintenanceModalLabel" class="font-weight-bold">Maintenance</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CRow>
                            <CCol sm="4">
                                <label class="form-label font-weight-bold">Mesin</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="8">
                                <CInput id="MachineName" v-model="MachineName" class="font-weight-bold" readonly/>
                                <label id="errorMachineName" class="form-error" style="display: none; color: red;"></label>
                            </CCol>                                    
                        </CRow>
                        <CRow class="pb-2">
                            <CCol sm="4">
                                <label class="form-label font-weight-bold">Catatan</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="8">
                                <textarea class="form-control" rows="2" v-model="Note"></textarea>
                                <label id="errorNote" class="form-error" style="display: none; color: red;"></label>
                            </CCol>                                    
                        </CRow>
                        <CRow>
                            <CCol sm="4">
                                <label class="form-label font-weight-bold">Downtime</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="8">
                                <input type="checkbox" v-model="HasDownTime"/> <label>Memiliki Downtime</label>
                                <label id="errorHasDownTime" class="form-error" style="display: none; color: red;"></label>
                            </CCol>                                    
                        </CRow>
                        <br>
                        
                        <CButton type="button" @click="saveClick()" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import productionMasterServices from '../Script/ProductionMasterServices';

export default {
    name: 'MaintenanceForm',
    components: {
    },
    props: ['saveMaintenanceGrid'],
    mounted:  function () {
    },
    data: function () {
        return {
            Error: 0,

            ProductionMasterId: null,
            ProductionStatus: null,
            MachineId: null,
            MachineName: null,
            Status: null,
            Note: null,
            HasDownTime: false,
        }
    },
    methods: {
        async addClick(productionMasterId, machineId, machineName, status){ 
            //Set Data
            this.ProductionMasterId = productionMasterId;
            this.ProductionStatus = status;
            this.MachineId = machineId;
            this.MachineName = machineName;
            this.Status = "New";

            window.$('#maintenanceModal').modal('show');
        },
        inputValidation(){
            this.Error = 0;
            
            if(this.ProductionStatus.toLowerCase() != 'on progress') {
                this.$swal("Error", "Tidak dapat melakukan Request Maintenance karena status produksi belum 'On Progress'. Silahkan update status produksi terlebih dahulu", "error");
                this.Error++;
            }
        },
        async saveClick(){
            this.inputValidation();

            //Proses
            if(this.Error == 0){
                this.$loading(true);
                
                const maintenanceData = {
                    status: this.Status,
                    machine_id: this.MachineId,
                    production_id: this.ProductionMasterId,
                    notes: this.Note,
                    has_downtime: this.HasDownTime
                }

                //QC Standar
                const variables = {
                    data : maintenanceData
                }

                productionMasterServices.addMaintenanceQuery(variables).then(res => {
                    this.$loading(false);
                    this.$swal("Info", "Data telah berhasil disimpan, menunggu untuk disetujui", "success").then(
                        this.$props.saveMaintenanceGrid()
                    );
                }).catch(error => {
                    this.$loading(false);
                    var errorMessage = this.$globalfunc.errorExtractor(error)
                    this.$swal("Error", errorMessage, "error");
                })
            }
        },
    }
}
</script>