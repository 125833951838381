<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Produksi Master : {{ProductionMasterCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode Produksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProductionMasterCode" v-model="ProductionMasterCode" class="font-weight-bold" readonly/>
                            <label id="errorProductionMasterCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Mesin</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Machine" class="pb-3" :options="MachineData" v-model="Machine" disabled/>
                            <label id="errorMachine" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal Buat</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="CreatedDate" v-model="CreatedDate" class="font-weight-bold" readonly/>
                            <label id="errorCreatedDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode Perencanaan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProductionPlanningCode" v-model="ProductionPlanningCode" class="font-weight-bold" readonly/>
                            <label id="errorProductionPlanningCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode Penjadwalan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProductionScheduleCode" v-model="ProductionScheduleCode" class="font-weight-bold" readonly/>
                            <label id="errorProductionScheduleCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Item</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Item" class="pb-3" :options="ItemData" v-model="Item" disabled/>
                            <label id="errorItem" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tgl. Mulai Produksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProductionStartDate" v-model="ProductionStartDate" class="font-weight-bold" readonly/>
                            <label id="errorProductionStartDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tgl. Estimasi Produksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProductionEstimationDate" v-model="ProductionEstimationDate" class="font-weight-bold" readonly/>
                            <label id="errorProductionEstimationDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tgl. Selesai Produksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProductionEndDate" v-model="ProductionEndDate" class="font-weight-bold" readonly/>
                            <label id="errorProductionEndDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Status" :options="StatusData" v-model="Status" @input="onChangeStatus()"/>
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Nilai Konversi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="ConversionAmount" v-model="ConversionAmount" class="font-weight-bold form-control input-number" readonly/>
                            <label id="errorConversionAmount" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <hr>    
                    <CRow class="pb-3">
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Satuan Dasar</label>
                            <label class="form-label font-weight-bold">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input id="UOM" v-model="UOM" class="font-weight-bold form-control" readonly/>
                            <label id="errorUOM" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Estimasi Produksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="ProductionQuantity" v-model="ProductionQuantity" class="font-weight-bold form-control input-number" readonly/>
                            <label id="errorProductionQuantity" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Hasil Produksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="ApprovedQuantity" v-model="ApprovedQuantity" class="font-weight-bold form-control input-number" readonly/>
                            <label id="errorApprovedQuantity" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow class="pb-3">
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Satuan Konversi</label>
                            <label class="form-label font-weight-bold">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input id="UOMConversion" v-model="UOMConversion" class="font-weight-bold form-control" readonly/>
                            <label id="errorUOMConversion" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Estimasi Produksi Konversi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="ProductionQuantityConversion" v-model="ProductionQuantityConversion" class="font-weight-bold form-control input-number" readonly/>
                            <label id="errorProductionQuantityConversion" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Hasil Produksi Konversi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="ApprovedQuantityConversion" v-model="ApprovedQuantityConversion" class="font-weight-bold form-control input-number" readonly/>
                            <label id="errorApprovedQuantityConversion" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <hr>
                    <CRow class="pb-3">
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Total Hasil Jadi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="TotalResult" v-model="TotalResult" class="font-weight-bold form-control input-number" readonly/>
                            <label id="errorTotalResult" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Total Hasil Lain</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="TotalWaste" v-model="TotalWaste" class="font-weight-bold form-control input-number" readonly/>
                            <label id="errorTotalWaste" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Total Seluruh Produksi </label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="TotalProduction" v-model="TotalProduction" class="font-weight-bold form-control input-number" readonly/>
                            <label id="errorTotalProduction" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Total Material</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="TotalMaterial" v-model="TotalMaterial" class="font-weight-bold form-control input-number" readonly/>
                            <label id="errorTotalMaterial" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Total Material Terpakai</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="TotalUsedMaterial" v-model="TotalUsedMaterial" class="font-weight-bold form-control input-number" readonly/>
                            <label id="errorTotalUsedMaterial" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Total Material Loss</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="TotalLossMaterial" v-model="TotalLossMaterial" class="font-weight-bold form-control input-number" readonly/>
                            <label id="errorTotalLossMaterial" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <hr>

                    <CTabs>
                        <CTab title="Material" id="tab-1" active :key="GridMaterialReload">
                            <div class="py-2">
                                <button class="btn btn-primary" v-if="this.ProductionStatus == 'On Progress'"
                                    @click="requestClick('Material')" >
                                    <i class="fa fa-plus"></i> Request Material
                                </button>
                            </div>

                            <div v-if="this.Status == 'Finish'">
                                <datasource ref="MaterialDataSource" :data="this.MaterialGridData" :page-size="20" :schema-model-fields="this.MaterialGridDataEditableSchemaModel"/>
                            </div>

                            <div v-else>
                                <datasource ref="MaterialDataSource" :data="this.MaterialGridData" :page-size="20" :schema-model-fields="this.MaterialGridDataSchemaModel"/>
                            </div>
                            
                            <kendo-grid ref="gridMaterial"
                                :data-source-ref="'MaterialDataSource'"
                                :editable="true"
                                :pageable="true"
                                :resizable="true"
                                :filterable="true"
                                :sortable="true"
                            >

                                <kendo-grid-column  :field="'material_code'"
                                                    :title="'Kode Material'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'material_name'"
                                                    :title="'Nama Material'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'requested_quantity'"
                                                    :title="'Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'additional_quantity'"
                                                    :title="'Additional Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right;'}"></kendo-grid-column>
                                
                                <kendo-grid-column  :field="'total_quantity'"
                                                    :title="'Total Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right;'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'used_quantity'"
                                                    :title="'Used Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right;'}"></kendo-grid-column>

                            </kendo-grid>

                            <request-material-form ref="requestMaterialForm" :saveRequestMaterialGrid="saveRequestMaterialGrid"/>
                        </CTab>
                        <CTab title="Quality Control" id="tab-2">
                            <div class="py-2">
                                <button class="btn btn-primary mr-2" v-if="this.ProductionStatus == 'On Progress'"
                                    @click="requestClick('WasteItem')" >
                                    <i class="fa fa-plus"></i> Request Waste Item
                                </button>
                                <button class="btn btn-primary" v-if="this.ProductionStatus == 'On Progress'"
                                    @click="requestClick('QC')" >
                                    <i class="fa fa-plus"></i> Request QC
                                </button>
                            </div>

                            <datasource ref="QCDataSource" :data="this.QCGridData" :page-size="20" :schema-model-fields="this.QCGridDataSchemaModel"/>

                            <kendo-grid ref="gridQC"
                                :data-source-ref="'QCDataSource'"
                                :editable="true"
                                :pageable="true"
                                :resizable="true"
                                :filterable="true"
                                :sortable="true"
                                :key="GridQCReload"
                            >

                                <kendo-grid-column  :field="'mutation_item_type'"
                                                    :title="'Tipe Item'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'status'"
                                                    :title="'Status'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode Item'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama Item'"
                                                    :width="250"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'base_uom'"
                                                    :title="'Satuan Dasar'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'requested_quantity'"
                                                    :title="'Request Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right;'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'total_approved_quantity'"
                                                    :title="'Approved Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right;'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'secondary_uom'"
                                                    :title="'Satuan Konversi'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'secondary_conv_amount'"
                                                    :title="'Nilai Konversi'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'secondary_requested_quantity'"
                                                    :title="'Requested Quantity Konversi'"
                                                    :width="250"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'secondary_total_approved_quantity'"
                                                    :title="'Approved Quantity Konversi'"
                                                    :width="250"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'created_at'"
                                                    :title="'Tanggal Dibuat'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:center;'}"></kendo-grid-column>
                            </kendo-grid>

                            <quality-control-form ref="qualityControlForm" :saveQCGrid="saveQCGrid"/>
                            <request-waste-item-form ref="requestWasteItemForm" :saveQCGrid="saveQCGrid"/>
                        </CTab>
                        <CTab title="Maintenance" id="tab-3">
                            <div class="py-2">
                                <button class="btn btn-primary" v-if="this.ProductionStatus == 'On Progress'"
                                    @click="requestClick('Maintenance')" >
                                    <i class="fa fa-plus"></i> Request Maintenance
                                </button>
                            </div>

                            <datasource ref="MaintenanceDataSource" :data="this.MaintenanceGridData" :page-size="20" :schema-model-fields="this.MaintenanceGridDataSchemaModel"/>

                            <kendo-grid ref="gridMaintenance"
                                :data-source-ref="'MaintenanceDataSource'"
                                :editable="true"
                                :pageable="true"
                                :resizable="true"
                                :filterable="true"
                                :sortable="true"
                                :key="GridMaintenanceReload"
                            >

                                <kendo-grid-column  :width="100"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:center'}"
                                                    :template="maintenanceColumnButton"></kendo-grid-column>

                                <kendo-grid-column  :field="'maintenance_code'"
                                                    :title="'Kode Maintenance'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'maintenance_date'"
                                                    :title="'Tanggal Maintenance'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'status'"
                                                    :title="'Status'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'machine_code'"
                                                    :title="'Kode Mesin'"
                                                    :width="250"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'machine_name'"
                                                    :title="'Nama Mesin'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right;'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'downtime'"
                                                    :title="'Downtime (Menit)'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right;'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'notes'"
                                                    :title="'Catatan'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            </kendo-grid>

                            <maintenance-form ref="maintenanceForm" :saveMaintenanceGrid="saveMaintenanceGrid"/>
                        </CTab>
                        <CTab title="Production Mutation" id="tab-4">
                            <div class="py-2">
                            </div>

                            <datasource ref="ProductionMutationDataSource" :data="this.ProductionMutationGridData" :page-size="20" :schema-model-fields="this.ProductionMutationGridDataSchemaModel"/>
                            
                            <kendo-grid ref="gridProductionMutation"
                                :data-source-ref="'ProductionMutationDataSource'"
                                :editable="true"
                                :pageable="true"
                                :resizable="true"
                                :filterable="true"
                                :sortable="true"
                                :key="GridProductionMutationReload"
                            >

                                <kendo-grid-column  :field="'mutation_item_type'"
                                                    :title="'Tipe Item'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'status'"
                                                    :title="'Status'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode Item'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama Item'"
                                                    :width="250"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                                    <kendo-grid-column  :field="'base_uom'"
                                                    :title="'Satuan Dasar'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'requested_quantity'"
                                                    :title="'Request Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right;'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'total_approved_quantity'"
                                                    :title="'Approved Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right;'}"></kendo-grid-column>

                                                    <kendo-grid-column  :field="'secondary_uom'"
                                                    :title="'Satuan Konversi'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'secondary_conv_amount'"
                                                    :title="'Nilai Konversi'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'secondary_requested_quantity'"
                                                    :title="'Requested Quantity Konversi'"
                                                    :width="250"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'secondary_total_approved_quantity'"
                                                    :title="'Approved Quantity Konversi'"
                                                    :width="250"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'created_at'"
                                                    :title="'Tanggal Dibuat'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:center;'}"></kendo-grid-column>
                            </kendo-grid>
                        </CTab>
                    </CTabs>

                    <br>
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/production-master')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import productionMasterServices from '../Script/ProductionMasterServices';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';

//Form
import requestMaterialForm from './RequestMaterialForm.vue';
import qualityControlForm from './QualityControlForm.vue';
import requestWasteItemForm from './RequestWasteItemForm.vue';
import maintenanceForm from './MaintenanceForm.vue';

export default {
    name: 'ProductionMasterForm',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column' : GridColumn,
        'datasource' : DataSource,
        'request-material-form' : requestMaterialForm,
        'quality-control-form' : qualityControlForm,
        'request-waste-item-form' : requestWasteItemForm,
        'maintenance-form' : maintenanceForm,
    },
    async mounted () {
        var gridMaintenance = this.$refs.gridMaintenance.kendoWidget();
        var GridElement = gridMaintenance.element;
        var vue = this;

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = gridMaintenance.dataItem(e.target.closest("tr"));

            vue.$router.push({ name: 'Maintenance Form', params: { formtype:'Edit', id:dataItem.maintenance_id, view:true } });
        });

        if(this.FormType == 'Add'){
            //Do Nothing
        } else {
            var data = await productionMasterServices.getProductionMasterQuery(this.Id);
            
            this.ProductionMasterCode = data.production_code;
            this.ProductionMasterId = data.production_id;
            this.ProductionPlanningId = data.production_planning_id;
            this.ProductionPlanningCode = data.production_planning_code;
            this.ProductionScheduleId = data.production_schedule_id;
            this.ProductionScheduleCode = data.production_schedule_code;

            this.FormulaId = data.formula_id;

            this.MachineData = [];
            var machineLabel = data.machine_code + " (" + data.machine_name + ")";
            this.Machine = { label: machineLabel, value: data.machine_id }
            this.ItemData = [];
            var itemLabel = data.item_code + " (" + data.item_name + ")";
            this.Item = { label: itemLabel, value: data.item_id }
            this.ItemCode = data.item_code;
            this.ItemName = data.item_name;
            

            this.CreatedDate = data.created_at;
            this.ProductionStartDate = data.production_start_dt;
            this.ProductionEstimationDate = data.production_estimation_dt;
            this.ProductionEndDate = data.production_end_dt;

            this.ProductionTypeData = [];
            this.ProductionType = data.production_type;
            var statusData = await this.$globalfunc.globalDropdown('ddl_status_production_master');
            this.StatusData = statusData.filter(status => {
                                return (status.value !== 'New') && (status.value  !== 'Ready') && (status.value  !== 'Request Material') && (status.value  !== 'Delete');
                            });
            this.Status = data.status;
            this.ProductionStatus = data.status;

            this.UOM = data.base_uom;
            this.UOMConversion = data.secondary_uom == null ? data.base_uom : data.secondary_uom;
            this.ProductionQuantity = data.production_qty
            this.ApprovedQuantity = data.approved_qty
            this.ProductionQuantityConversion = data.secondary_uom == null ? data.production_qty : data.secondary_production_qty;
            this.ApprovedQuantityConversion = data.secondary_uom == null ? data.approved_qty : data.secondary_approved_qty;
            this.ConversionAmount = data.secondary_uom == null ? 1 : data.secondary_conv_amount;

            //Total
            this.TotalResult = data.total_result_weight;
            this.TotalWaste = data.total_waste_weight;
            this.TotalProduction = data.total_production_weight;
            this.TotalMaterial = data.total_material_weight;
            this.TotalUsedMaterial = data.total_used_material_weight;
            this.TotalLossMaterial = data.total_lost_weight;

            //Grid
            this.MaterialGridData = productionMasterServices.generateMaterialDetail(data.detail_materials);
            this.QCGridData = data.production_results == null ? [] : data.production_results;
            this.ProductionMutationGridData = data.production_mutations == null ? [] : data.production_mutations;
            this.MaintenanceGridData = data.maintenances == null ? [] : data.maintenances;
            
            this.SaveType = 'Edit';

            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Error: 0,
            Data: null,

            ProductionMasterId: null,
            ProductionMasterCode: null,
            ProductionPlanningId: null,
            ProductionPlanningCode: null,
            ProductionScheduleId: null,
            ProductionScheduleCode: null,
            FormulaId: null,

            MachineData: [],
            Machine: null,
            ItemData: [],
            Item: null,
            ItemCode: null,
            ItemName: null,
            
            CreatedDate: null,
            ProductionStartDate: null,
            ProductionEstimationDate: null,
            ProductionEndDate: null,

            ProductionTypeData: [],
            ProductionType: null,
            StatusData: [],
            Status: null,
            ProductionStatus: null,

            UOM: null,
            UOMConversion: null,
            ProductionQuantity: 0,
            ApprovedQuantity: 0,
            ProductionQuantityConversion: 0,
            ApprovedQuantityConversion: 0,
            ConversionAmount: 0,

            //Total
            TotalResult: 0,
            TotalWaste: 0,
            TotalProduction: 0,
            TotalMaterial: 0,
            TotalUsedMaterial: 0,
            TotalLossMaterial: 0,

            // Grid
            MaterialGridData: [],
            MaterialGridDataSchemaModel: {
                production_detail_material_id: { type: "string", editable: false },
                production_id: { type: "string", editable: false },
                material_id: { type: "string", editable: false },
                material_code: { type: "string", editable: false },
                material_name: { type: "string", editable: false },
                requested_quantity: { type: "number", editable: false },
                additional_quantity: { type: "number", editable: false },
                total_quantity: { type: "number", editable: false },
                used_quantity: { type: "number", editable: false }
            },
            MaterialGridDataEditableSchemaModel: {
                production_detail_material_id: { type: "string", editable: false },
                production_id: { type: "string", editable: false },
                material_id: { type: "string", editable: false },
                material_code: { type: "string", editable: false },
                material_name: { type: "string", editable: false },
                requested_quantity: { type: "number", editable: false },
                additional_quantity: { type: "number", editable: false },
                total_quantity: { type: "number", editable: false },
                used_quantity: { type: "number", editable: true }
            },
            QCGridData: [],
            QCGridDataSchemaModel: {
                mutation_item_type: { type: "string", editable: false },
                status: { type: "string", editable: false },
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                base_uom: { type: "string", editable: false },
                requested_quantity: { type: "number", editable: false },
                total_approved_quantity: { type: "number", editable: false },
                secondary_uom: { type: "string", editable: false },
                secondary_conv_amount: { type: "number", editable: false },
                secondary_requested_quantity: { type: "number", editable: false },
                secondary_total_approved_quantity: { type: "number", editable: false },
                created_at: { type: "string", editable: false },
            },
            ProductionMutationGridData: [],
            ProductionMutationGridDataSchemaModel: {
                mutation_item_type: { type: "string", editable: false },
                status: { type: "string", editable: false },
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                requested_quantity: { type: "number", editable: false },
                total_approved_quantity: { type: "number", editable: false },
                created_at: { type: "string", editable: false },
            },
            MaintenanceGridData: [],
            MaintenanceGridDataSchemaModel: {
                maintenance_code: { type: "string", editable: false },
                maintenance_date: { type: "string", editable: false },
                status: { type: "string", editable: false },
                machine_code: { type: "string", editable: false },
                machine_name: { type: "string", editable: false },
                downtime: { type: "number", editable: false },
                notes: { type: "string", editable: false },
            },

            GridMaterialReload: 0,
            GridQCReload: 0,
            GridMaintenanceReload: 0,
            GridProductionMutationReload: 0,
            SaveType: null,
        }
    },
    methods: {
        maintenanceColumnButton(){
            return this.$globalfunc.gridActionButtonViewOnly("Maintenance")
        },
        requestClick(type){
            if(type == "Material"){
                this.$refs.requestMaterialForm.addClick(this.ProductionMasterId, this.ProductionScheduleId, this.FormulaId);
            }
            if(type == "QC"){
                this.$refs.qualityControlForm.addClick(this.ProductionMasterId, this.ItemCode, this.ItemName, this.ProductionStatus);
            }
            if(type == "WasteItem"){
                this.$refs.requestWasteItemForm.addClick(this.ProductionMasterId, this.ProductionStatus);
            }
            if(type == "Maintenance"){
                this.$refs.maintenanceForm.addClick(this.ProductionMasterId, this.Machine.value, this.Machine.label, this.ProductionStatus);
            }
        },
        saveRequestMaterialGrid(){
            window.$('#requestMaterialModal').modal('hide');
            this.GridMaterialReload++;
            this.GridProductionMutationReload++;
            this.gridDataReload();
        },
        saveQCGrid(){
            window.$('#qualityControlModal').modal('hide');
            window.$('#requestWasteItemModal').modal('hide');
            this.GridQCReload++;
            this.GridProductionMutationReload++;
            this.gridDataReload();
        },
        saveMaintenanceGrid(){
            window.$('#maintenanceModal').modal('hide');
            this.GridMaintenanceReload++;
            this.gridDataReload();
        },
        onChangeStatus(){
            this.Status = this.Status == null ? null : this.Status.value;
            this.GridMaterialReload++;
        },
        async gridDataReload(){
            var data = await productionMasterServices.getProductionMasterQuery(this.Id);

            //Grid
            this.MaterialGridData = productionMasterServices.generateMaterialDetail(data.detail_materials);
            this.QCGridData = data.production_results == null ? [] : data.production_results;
            this.ProductionMutationGridData = data.production_mutations == null ? [] : data.production_mutations;
            this.MaintenanceGridData = data.maintenances == null ? [] : data.maintenances;
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");
            var materialData = this.$refs.gridMaterial.kendoWidget().dataSource._data;

            //Validation
            if(this.Status == '' || this.Status == null){                
                this.errorShow('errorStatus');
            }
            
            if(this.Status == "Finish"){
                for(var i=0; i<materialData.length; i++){
                    if(materialData[i].used_quantity <= 0) {
                        this.$swal("Error", "Used Quantity pada material tidak boleh kosong", "error");
                        this.Error++;
                        break;
                    }
                }
            }
        },
        saveClick(){
            this.inputValidation();
            
            //Proses
            if(this.Error == 0){
                if (this.SaveType == 'Add'){
                    //Do Nothing
                }else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    var materialData = this.$refs.gridMaterial.kendoWidget().dataSource._data;
                    const detailMaterials = productionMasterServices.convertMasterMaterialSave(materialData);

                    var masterProduction = {
                        production_id: this.ProductionMasterId,
                        production_code: 'Dummy',
                        // production_code: this.ProductionMasterCode,
                        production_type: this.ProductionType,
                        status: this.Status,
                        machine_id: this.Machine.value,
                        item_id: this.Item.value,
                        production_qty: this.ProductionQuantity,
                        detail_materials: detailMaterials
                    }

                    const variables = {
                        id: this.ProductionMasterId,
                        data: masterProduction
                    }

                    productionMasterServices.editMasterProductionQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/production-master'
                                }
                            });     
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode, message){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = message == undefined ? response.fieldRequired : message;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    } 
}
</script>