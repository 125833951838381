<template>
    <div>
        <div class="modal fade" id="requestWasteItemModal" tabindex="-1" aria-labelledby="requestWasteItemModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="requestWasteItemModalLabel" class="font-weight-bold">Request Waste Item</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="col-xs-6 text-light pb-2">
                            <button id="add" class="btn btn-primary"
                                @click="addWasteItemClick()">
                                <i class="fa fa-plus"></i> Add Waste Item
                            </button>
                        </div>

                        <datasource ref="WasteItemDataSource" :data="this.WasteItemGridData" :page-size="10" :schema-model-fields="this.WasteItemGridDataSchemaModel"/>

                        <kendo-grid ref="gridWasteItem"
                            :data-source-ref="'WasteItemDataSource'"
                            :editable="true"
                            :pageable="true"
                            :resizable="true"
                            :filterable="true"
                            :sortable="true"
                        >
                            <kendo-grid-column  :field="'type_detail_id'"
                                                :title="'Kode Tipe Detail'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'type_detail_name'"
                                                :title="'Nama Tipe Detail'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'product_code'"
                                                :title="'Kode Item'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'item_name'"
                                                :title="'Nama Item'"
                                                :width="300"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'quantity'"
                                                :title="'Quantity'"
                                                :width="100"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                            
                            <kendo-grid-column  :title="'&nbsp;'"
                                                :attributes="{ class: 'k-text-center' }"
                                                :command="['destroy']"
                                                :width="100"></kendo-grid-column>
                        </kendo-grid>
                        <br/>

                        <waste-item-form ref="wasteItemForm" :saveWasteItemGrid="saveWasteItemGrid"/>
                        
                        <CButton type="button" @click="saveClick()" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import productionMasterServices from '../Script/ProductionMasterServices';

//Form
import wasteItemForm from './WasteItemForm.vue';

export default {
    name: 'RequestWasteItemForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column' : GridColumn,
        'datasource' : DataSource,
        'waste-item-form' : wasteItemForm,
    },
    props: ['saveQCGrid'],
    mounted:  function () {
    },
    data: function () {
        return {
            Error: 0,

            ProductionMasterId: null,
            ProductionStatus: null,

            //Grid
            WasteItemGridData: [],
            WasteItemGridDataSchemaModel: {
                item_id: { type: "string", editable: false },
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                type_detail_id: { type: "string", editable: false },
                type_detail_name: { type: "string", editable: false },
                quantity: { type: "number", editable: true },
            },
        }
    },
    methods: {
        async addClick(id, status){ 
            this.WasteItemGridData = [];
            this.ProductionMasterId = id;
            this.ProductionStatus = status;

            window.$('#requestWasteItemModal').modal('show');
        },
        addWasteItemClick(){
            this.$refs.wasteItemForm.addClick();
        },
        saveWasteItemGrid(dataWasteItem){
            var gridData = this.$refs.gridWasteItem.kendoWidget().dataSource._data;
            var requestWasteItemData = this.$globalfunc.objectToArrayConverter(gridData, 'ProductionMaster-WasteItemGrid');

            var duplicate = false;
            if(dataWasteItem.length > 0){
                duplicate = productionMasterServices.duplicateItemChecker(requestWasteItemData, dataWasteItem[0]);
            }

            if(duplicate){
                this.$swal("Error", "Item sudah ada", "error");
            }else{
                requestWasteItemData = requestWasteItemData.concat(dataWasteItem);
                this.WasteItemGridData = requestWasteItemData;
            }

            window.$('#wasteItemModal').modal('hide');
        },
        inputValidation(){
            this.Error = 0;
            var requestWasteItemData = this.$refs.gridWasteItem.kendoWidget().dataSource._data;

            if (requestWasteItemData.length == 0) {
                this.Error++;
                this.$swal("Error", "Data Request Waste item harus di isi", "error");
            }
            else {
                for(var i=0; i<requestWasteItemData.length; i++){
                    if (requestWasteItemData[i].quantity <= 0) {
                        this.$swal("Error", "Quantity tidak boleh kosong", "error");
                        this.Error++;
                        break;
                    }
                }
            }
            
            if(this.ProductionStatus.toLowerCase() != 'on progress') {
                this.$swal("Error", "Tidak dapat melakukan Request Waste Item karena status produksi belum 'On Progress'. Silahkan update status produksi terlebih dahulu", "error");
                this.Error++;
            }
        },
        async saveClick(){
            this.inputValidation();

            //Proses
            if(this.Error == 0){
                //QC Waste Item
                this.$loading(true);

                var requestWasteItemData = this.$refs.gridWasteItem.kendoWidget().dataSource._data;
                const items = productionMasterServices.convertWasteItemSave(requestWasteItemData);
                
                const variables = {
                    id : parseInt(this.ProductionMasterId),
                    quantity : null,
                    mutationId : null,
                    data : items,
                }

                productionMasterServices.addQCQuery(variables).then(res => {
                    this.$loading(false);
                    this.$swal("Info", "Data telah berhasil disimpan, menunggu untuk disetujui", "success").then(
                        this.$props.saveQCGrid()
                    );
                }).catch(error => {
                    this.$loading(false);
                    var errorMessage = this.$globalfunc.errorExtractor(error)
                    this.$swal("Error", errorMessage, "error");
                })
            }
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>