<template>
    <div>
        <div class="modal fade" id="requestMaterialModal" tabindex="-1" aria-labelledby="requestMaterialModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="requestMaterialModalLabel" class="font-weight-bold">Request Material</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="col-xs-6 text-light pb-2">
                            <button id="add" class="btn btn-primary"
                                @click="addMaterialClick()">
                                <i class="fa fa-plus"></i> Add Material
                            </button>
                        </div>
                        <datasource ref="RequestMaterialDataSource" :data="this.RequestMaterialGridData" :page-size="10" :schema-model-fields="this.RequestMaterialGridDataSchemaModel"/>

                        <kendo-grid ref="gridRequestMaterial"
                            :data-source-ref="'RequestMaterialDataSource'"
                            :editable="true"
                            :pageable="true"
                            :resizable="true"
                            :filterable="true"
                            :sortable="true"
                        >
                            <kendo-grid-column  :field="'storage_id'"
                                                :title="'Kode Gudang'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                               
                            <kendo-grid-column  :field="'type_detail_id'"
                                                :title="'Kode Tipe Detail'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'material_code'"
                                                :title="'Kode Material'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'material_name'"
                                                :title="'Nama Material'"
                                                :width="300"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'quantity'"
                                                :title="'Quantity'"
                                                :width="100"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                            
                            <kendo-grid-column  :title="'&nbsp;'"
                                                :attributes="{ class: 'k-text-center' }"
                                                :command="['destroy']"
                                                :width="100"></kendo-grid-column>
                        </kendo-grid>
                        <br/>

                        <material-form ref="materialForm" :saveMaterialGrid="saveMaterialGrid"/>
                        
                        <CButton type="button" @click="saveClick()" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import productionMasterServices from '../Script/ProductionMasterServices';

//Form
import materialForm from '../../ProductionMaster/Component/MaterialForm.vue';

export default {
    name: 'RequestMaterialForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column' : GridColumn,
        'datasource' : DataSource,
        'material-form' : materialForm,
    },
    props: ['saveRequestMaterialGrid'],
    mounted:  function () {
    },
    data: function () {
        return {
            Error: 0,

            ProductionMasterId: null,
            ProductionScheduleId: null,
            FormulaId: null,

            //Grid
            RequestMaterialGridData: [],
            RequestMaterialGridDataSchemaModel: {
                material_id: { type: "string", editable: false },
                material_code: { type: "string", editable: false },
                material_name: { type: "string", editable: false },
                storage_id: { type: "string", editable: false },
                type_detail_id: { type: "string", editable: false },
                quantity: { type: "number", editable: false },
            },
        }
    },
    methods: {
        async addClick(productionMasterId, productionScheduleId, formulaId){ 
            //Set Data
            this.RequestMaterialGridData = [];
            this.ProductionMasterId = productionMasterId;
            this.ProductionScheduleId = productionScheduleId;
            this.FormulaId = formulaId;

            window.$('#requestMaterialModal').modal('show');
        },
        addMaterialClick(){
            this.$refs.materialForm.addClick(this.FormulaId);
        },
        saveMaterialGrid(dataMaterial){
            var gridData = this.$refs.gridRequestMaterial.kendoWidget().dataSource._data;
            var requestmaterialData = this.$globalfunc.objectToArrayConverter(gridData, 'ProductionMaster-RequestMaterialGrid');
            var materialData = this.$globalfunc.objectToArrayConverter(dataMaterial, 'ProductionMaster-MaterialGrid');

            var duplicate = false;
            if(dataMaterial.length > 0){
                duplicate = productionMasterServices.duplicateMaterialChecker(requestmaterialData, materialData[0]);
            }

            if(duplicate){
                this.$swal("Error", "Material sudah ada", "error");
            }else{
                requestmaterialData = requestmaterialData.concat(materialData);
                this.RequestMaterialGridData = requestmaterialData;
            }
            
            window.$('#materialModal').modal('hide');
        },
        inputValidation(){
            this.Error = 0;
            var requestMaterialData = this.$refs.gridRequestMaterial.kendoWidget().dataSource._data;

            if (requestMaterialData.length == 0) {
                this.Error++;
                this.$swal("Error", "Data Request Material harus di isi", "error");
            }
            else {
                for(var i=0; i<requestMaterialData.length; i++){
                    if (requestMaterialData[i].quantity <= 0) {
                        this.$swal("Error", "Quantity tidak boleh kosong", "error");
                        this.Error++;
                        break;
                    }
                }
            }
        },
        async saveClick(){
            this.inputValidation();

            //Proses
            if(this.Error == 0){
                this.$loading(true);

                var requestMaterialData = this.$refs.gridRequestMaterial.kendoWidget().dataSource._data;
                const items = productionMasterServices.convertMaterialSave(requestMaterialData);
                
                var requestAdditionalProductionMaterial = {
                    production_mutation_type: "request_additional_production_material",
                    production_mutation_date: null,
                    status: "New",
                    production_id: this.ProductionMasterId,
                    production_schedule_id: this.ProductionScheduleId,
                    notes: null,
                    items: items
                }
                
                const variables = {
                    data : requestAdditionalProductionMaterial
                }

                productionMasterServices.addRequestMaterialQuery(variables).then(res => {
                    this.$loading(false);
                    this.$swal("Info", "Data telah berhasil disimpan, menunggu untuk disetujui", "success").then(
                        this.$props.saveRequestMaterialGrid()
                    );
                }).catch(error => {
                    this.$loading(false);
                    var errorMessage = this.$globalfunc.errorExtractor(error)
                    this.$swal("Error", errorMessage, "error");
                })
            }
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>